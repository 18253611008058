import React from 'react'
import {graphql, Link} from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/seo'
import Share from '../components/share'
import PortableText from '../components/portableText'
import {format} from 'date-fns'
import {getPostUrl, getFullUrl} from '../lib/helpers'
import Sidebar from "../components/sidebar";
import Article from "../components/article";
import Vimeo from "../components/vimeo";

export const query = graphql`
query PostQuery($id: String!) {
    post: sanityPost(id: {eq: $id}) {
        id
        publishedAt
        categories {
            _id
            title
        }
        tags
        mainImage {
            ...MainImage
            vimeo
        }
        mobileImage: mainImage {
            ...MobileMainImage
        }
        title
        slug {
          current
        }
        _rawExcerpt(resolveReferences: {maxDepth: 5})
        _rawBody(resolveReferences: {maxDepth: 5})
        authors {
            _key
            author {
            ...AuthorInfo
            }
        }
    }
}
`

class PostPage extends React.Component {
    constructor(props) {
        super(props);
        const {data, errors} = props
        this.state = {
            post: (data || {}).post,
            playVideo: false,
        }
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(e) {
        if(this.state.post.mainImage.hasOwnProperty('vimeo') && this.state.post.mainImage.vimeo) {
            this.setState({
                playVideo: !this.state.playVideo
            })
        }
    }

    render() {
        return (
            <>
                <SEO
                    title={this.state.post.title}
                    image={this.state.post.mainImage ? this.state.post.mainImage : null}
                />
                <Article
                    title={this.state.post.title}
                    excerpt={(<PortableText blocks={this.state.post._rawExcerpt}/>)}
                    byline={(
                        <div className='mr-4 uppercase text-sm mb-6'>
                            Publisert {format(this.state.post.publishedAt, 'DD/MM/YYYY')}
                        </div>
                    )}
                    image={(
                        <div>
                            {this.state.post.mainImage.hasOwnProperty('vimeo') && this.state.post.mainImage.vimeo && (<Vimeo node={{url: this.state.post.mainImage.vimeo }} />)}
                            {!(this.state.post.mainImage.hasOwnProperty('vimeo') && this.state.post.mainImage.vimeo) && (<Img fluid={this.state.post.mainImage.asset.fluid}/>)}
                        </div>
                    )}
                    mobileImage={(
                        <div>
                            {this.state.post.mainImage.hasOwnProperty('vimeo') && this.state.post.mainImage.vimeo && (<Vimeo node={{url: this.state.post.mainImage.vimeo }} />)}
                            {!(this.state.post.mainImage.hasOwnProperty('vimeo') && this.state.post.mainImage.vimeo) && (<Img fluid={this.state.post.mobileImage.asset.fluid}/>)}
                        </div>
                    )}
                    sidebar={(
                        <Sidebar before={(
                            <>
                                {/*<div className='mb-8 -mx-2 flex flex-wrap'>*/}
                                {/*    {this.state.post.tags && this.state.post.tags.map(tag => (*/}
                                {/*        <div className='px-2 mb-4' key={tag}>*/}
                                {/*            <Link*/}
                                {/*                className='font-heading text-base font-semibold'*/}
                                {/*                to={`/tag/${tag}`}>*/}
                                {/*                {tag}*/}
                                {/*            </Link>*/}
                                {/*        </div>*/}
                                {/*    ))}*/}
                                {/*</div>*/}
                            </>
                        )}/>
                    )}>
                    <div className="body-text">
                        {this.state.post._rawBody &&
                        <PortableText blocks={this.state.post._rawBody}/>}
                    </div>

                    <Share
                        url={getFullUrl(getPostUrl(this.state.post.publishedAt, this.state.post.slug))}
                        title={this.state.post.title}/>
                </Article>
            </>
        )
    }
}

export default PostPage
